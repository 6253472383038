/**
 *  * This file is part of FourthStar Checkout Page
 *------------------------------------------------------
 *@module CheckoutFSTR
 *@developer Shiv Pandey
 */

import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { GlobalStore } from "store/GlobalStore";
import CircularProgress from "@mui/material/CircularProgress";

import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import abiJson from "constant/abi-json";
import Loader from "components/Loader/Loader";
import ShadowFrame from "components/shadow-frame";

import Web3 from "web3";
import Alert from "components/popup/Alert/Alert";
import SelectWalletAccount from "components/popup/SelectWalletAccount/SelectWalletAccount";
import TransactionSuccess from "components/popup/TransactionSuccess/TransactionSuccess";
import TransactionFailed from "components/popup/TransactionFailed/TransactionFailed";
import { useGetMyOrderDetailQuery } from "store/slicer/MyOrders";

import { useWebSocket } from "../../services/socket/index"; // Import your WebSocket context
import { isMobile } from "mobile-device-detect";
import CheckoutFstr from "components/popup/CheckoutFstr";
import MetamaskInstall from "components/popup/CheckoutFstr/MetamaskInstall";
import WalletAddressModel from "components/Modal/Payments/WalletAddressModel";
import { useGetUserWalletListQuery } from "store/slicer/userProfile";
import TransactionCancel from "components/popup/TransactionCancel/TransactionCancel";

const CHAIN_ID = process.env.REACT_APP_WEB3_CHAIN_ID;
const CHAIN_NAME = process.env.REACT_APP_WEB3_CHAIN_NAME;
const CHAIN_RPC_URL = process.env.REACT_APP_WEB3_CHAIN_RPC_URL;
const CURRENCY_NAME = process.env.REACT_APP_WEB3_NATIVE_CURRENCY_NAME;
const CURRENCY_SYMBOL = process.env.REACT_APP_WEB3_NATIVE_CURRENCY_SYMBOL;
const FSTR_CONTRACT = process.env.REACT_APP_WEB3_FSTR_CONTRACT;

const CheckoutFSTR = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  const { state } = location;
  const orderId = state?.orderId;
  const isSubscription = state?.isSubscription;

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  // this checks for paid content, if we are not passing required fields in search params then go back, unless its for cart or subscription
  if (!orderId && !state?.orderId) {
    navigate("/marketplace");
  }

  const [loading, setLoading] = useState(false);
  const [processingText, setProcessingText] = useState(
    "Your transaction is being processed."
  );
  const [showProcessingTip, setShowProcessingTip] = useState(false);
  const [showErrorTip, setShowErrorTip] = useState(false);

  const [metamaskInstalled, setMetamaskInstalled] = useState(true);

  const [rejectedWalletConnect, setRejectedWalletConnect] = useState(false);
  const [rejectedChainDownload, setRejectedChainDownload] = useState(false);
  const [rejectedChainSwitch, setRejectedChainSwitch] = useState(false);
  const [reRequestConnectWallet, setReRequestConnectWallet] = useState(false);
  const [reRequestSwitchChain, setReRequestSwitchChain] = useState(false);
  const [reRequestTransaction, setReRequestTransaction] = useState(false);

  const [canInitiateTrans, setCanInitiateTrans] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const selectedAccountRef = useRef(null);
  const web3Instance = useRef(null);
  const tokenAdded = useRef(false);
  const FSTRContract = useRef(null);

  const [rejectedTransaction, setRejectedTransaction] = useState(false);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState(
    "Something went wrong"
  );

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [activeTab, setActiveTab] = useState("autoPay");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(true);
  const [autoPayScanQr, setAutoPayScanQr] = useState("");
  const [manualEntryScanQr, setManualEntryScanQr] = useState("");
  const [qrLoading, setQrLoading] = useState(true);
  const [transactionRes, setTransactionRes] = useState({});
  const [minutes, setMinutes] = useState(7);
  const [seconds, setSeconds] = useState(0);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const { setToken, socket } = useWebSocket(); // Access the WebSocket context
  const [showChangeWalletPopup, setShowChangeWalletPopup] = useState(false);
  const [walletAddressList, setWalletAddressList] = useState([]);
  const [storeSessionData, setStoreSessionData] = useState({});
  const [showCommonErrorPopup, setShowCommonErrorPopup] = useState(false);
  const [showAlreadyInAsset, setShowAlreadyInAsset] = useState(false);
  const [openCancelPaymentPopup, setOpenCancelPaymentPopup] = useState(false);

  // const { data, isLoading, refetch } = useGetMyOrderDetailQuery({ orderId });

  const onWalletPopupClose = () => {
    setShowChangeWalletPopup(false);
  };

  // Initiate again transaction after change the wallet address
  const onActionNextBtnClick = async (web3FromAddress) => {
    // payload for checking out a single paid product
    setShowChangeWalletPopup(false);
    try {
      let payload = {
        paymentHash: transactionRes?.paymentHash,
        web3FromAddress: web3FromAddress,
      };
      let res = await fetch(
        BASE_URL + EndpointsSlug.MODIFY_CRYPTO_TRANSACTION,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }
      if (res?.response?.statusEnum != 1) {
        setAlertMessage(res.response?.message);
        setAlertSuccess(false);
        setShowCommonErrorPopup(true);
        // throw new Error(res.response?.message);
      } else {
        const existingData = sessionStorage.getItem(`${orderId}`);

        let parsedData = JSON.parse(existingData);
        delete parsedData.qrData;
        // Update fields as needed
        parsedData.gatewayTransaction = res?.response?.data.gatewayTransaction;
        sessionStorage.setItem(`${orderId}`, JSON.stringify(parsedData));
        setTransactionRes(res?.response?.data?.gatewayTransaction);
        localStorage.setItem(
          "transactionpaymenthashfstr",
          res?.response?.data?.gatewayTransaction?.paymentHash
        );
        selectedAccountRef.current =
          res?.response?.data?.gatewayTransaction?.web3FromAddress;
        localStorage.setItem(
          "web3ToAddress",
          res?.response?.data?.gatewayTransaction?.web3ToAddress
        );
        localStorage.setItem(
          "web3FromAddress",
          res?.response?.data?.gatewayTransaction?.web3FromAddress
        );
      }
    } catch (error) {
      setAlertMessage("Something went wrong please try again!");
      setAlertSuccess(false);
      setShowCommonErrorPopup(true);
      return;
    }
  };

  const addressLists = useGetUserWalletListQuery();

  useEffect(() => {
    setWalletAddressList(addressLists?.data?.response?.data?.web3Wallets);
  }, [addressLists]);

  // Copy the web3ToAddress of Payment details
  const copyToClipboard = (path) => {
    navigator?.clipboard?.writeText(path);
    // setOpenSnackbar(true)
  };

  const handlePaymentRequestTimeOut = () => {
    if (!transactionSuccess) {
      setAlertSuccess("pending");
      setAlertHeading("Payment Timeout");
      setAlertMessage(
        "Your payment session has expired. Please start the process again to complete your payment."
      );
      setShowAlert(true);
      setMinutes(0);
      setSeconds(0);
    }
  };

  // Go back when click on cancel button
  const goBack = () => {
    setQrLoading(false);
    navigate(-2); // Navigate back in history
  };

  const cancelTransaction = async () => {
    console.log("cancel transaction");
    if (transactionRes?.paymentHash) {
      try {
        let payload = {
          paymentHash: `${transactionRes?.paymentHash}`,
        };

        let res = await fetch(
          BASE_URL + EndpointsSlug.CANCEL_CRYPTO_TRANSACTION,
          {
            method: "POST",
            body: JSON.stringify(payload),
            mode: "cors",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (res === null) {
          throw new Error("Something went wrong!");
        }

        if (res.ok) {
          res = await res.json();
        }
        if (res?.response?.statusEnum === 1) {
          sessionStorage.removeItem(`${orderId}`);
          navigate("/marketplace");
        } else {
          setAlertMessage("Something went wrong please try again!");
          setAlertSuccess(false);
          setShowCommonErrorPopup(true);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage("Something went wrong please try again!");
        setAlertSuccess(false);
        setShowCommonErrorPopup(true);
        return;
      }
    }
  };

  //Get Selected Payment Option
  const getQrCode = async () => {
    setQrLoading(true);
    try {
      let payload = {
        amount: `${transactionRes?.amount}`,
      };

      let res = await fetch(
        BASE_URL + EndpointsSlug.GET_CRYPTO_TRANSACTION_QR,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }
      if (res?.response?.statusEnum == 1) {
        setAutoPayScanQr(res.response.data.withAmount);
        setManualEntryScanQr(res.response.data.withoutAmount);
        const existingData = sessionStorage.getItem(`${orderId}`);
        if (existingData) {
          let parsedData = JSON.parse(existingData);
          // Update fields as needed
          parsedData.qrData = res?.response?.data;
          sessionStorage.setItem(`${orderId}`, JSON.stringify(parsedData));
        }
      }
      setQrLoading(false);
    } catch (error) {
      setErrorPopupMessage("Something went wrong!");
      return;
    }
  };

  useEffect(() => {
    if (transactionRes?.paymentHash) {
      if (!storeSessionData?.qrData) {
        getQrCode();
      } else {
        setQrLoading(false);
        setAutoPayScanQr(storeSessionData?.qrData?.withAmount);
        setManualEntryScanQr(storeSessionData?.qrData?.withoutAmount);
      }
    }
  }, [transactionRes]);

  useEffect(() => {
    // Ensure the socket is ready before attempting to listen for events
    if (!socket) {
      console.log("Socket is not ready yet.");
      return;
    }

    // console.log("socket==============", socket);
    // Listen for the specific event
    socket.on("user_payment_status_web", (data) => {
      // console.log("Received payment event:", data);
      if (
        data?.info?.status == "success" &&
        transactionRes?.paymentHash == data?.info?.paymentHash
      ) {
        sessionStorage.removeItem(`${orderId}`);
        setShowSuccessPopup(true);
        setTransactionSuccess(true);
        setLoading(false);
        setMinutes(0);
        setSeconds(0);
      }
    });

    // Unsubscribe from the event when the component is unmounted
    return () => {
      socket.off("user_payment_status_web");
    };
  }, [socket, transactionRes?.paymentHash]);

  useEffect(() => {
    if (socket && !socket.connected) {
      console.log("Reconnecting socket...");
      socket.connect();
    }
  }, [socket]);

  useEffect(() => {
    if (!transactionRes?.paymentHash) return;
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        if (!transactionSuccess) {
          sessionStorage.removeItem(`${orderId}`);
          handlePaymentRequestTimeOut();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, seconds, transactionRes?.paymentHash]);

  const onErrorPopupClose = () => {
    setErrorPopupMessage("Something went wrong");
    setShowErrorPopup(false);
    setShowErrorTip(false);
  };

  const checkMetamask = async () => {
    // Check if MetaMask is installed
    if (window.ethereum && window.ethereum.isMetaMask) {
      // MetaMask is installed
      setMetamaskInstalled(true);
      console.log("MetaMask is installed");
      return true;
    } else {
      // MetaMask is not installed
      setMetamaskInstalled(false);
      console.error("MetaMask not detected!");
      return false;
    }
  };

  const initContract = (web3Inst) => {
    const contract = new web3Inst.eth.Contract(abiJson, FSTR_CONTRACT);
    FSTRContract.current = contract;
  };

  // Initialize Web3
  const initWeb3 = async () => {
    if (!metamaskInstalled) {
      return;
    }
    setLoading(true);
    setRejectedWalletConnect(false);
    if (window.ethereum) {
      const w3Instance = new Web3(window.ethereum);
      try {
        setLoading(true);
        // Request account access if needed
        await window.ethereum.enable();
        console.log("w3Instance", w3Instance);

        web3Instance.current = w3Instance;

        initContract(w3Instance);
      } catch (error) {
        console.log("User Denied account access", error);
        setRejectedWalletConnect(true);
        setLoading(false);
      }
    } else if (window.web3) {
      // Use the injected web3 instance
      const web3Inst = new Web3(window.web3.currentProvider);

      web3Instance.current = web3Inst;

      initContract(web3Inst);
    } else {
      console.log("No web3 instance injected, please install MetaMask");
    }
  };

  const addTokenToWallet = async (retriesLeft) => {
    try {
      // 1. fetch all user's accounts here, if there are more than one account then we should show a model to user to choose which account to transact with
      const accounts = await web3Instance.current.eth.getAccounts();
      const isAddedAlready =
        localStorage.getItem(selectedAccountRef.current + "-tokencache") ===
        "FSTR";
      if (!isAddedAlready) {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: FSTR_CONTRACT,
              symbol: "FSTR",
              decimals: 18,
            },
          },
        });
        if (!wasAdded) {
          console.log("user rejectect token import");
          setLoading(false);
          setShowErrorPopup(true);
          setErrorPopupMessage(
            "Something went wrong!. Add the tokens to your wallet for your ease access."
          );
          if (retriesLeft) {
            addTokenToWallet(retriesLeft - 1);
          }
        }
        localStorage.setItem(
          selectedAccountRef.current + "-tokencache",
          "FSTR"
        );
      }
      tokenAdded.current = true;
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setShowErrorPopup(true);
      setErrorPopupMessage(
        "Something went wrong!. Add the tokens to your wallet for your ease access."
      );
      if (retriesLeft) {
        addTokenToWallet(retriesLeft - 1);
      }
    }
    if (!retriesLeft) {
      // User don't want to add the token in his wallet
      tokenAdded.current = true;
    }
  };

  const switchChain = async () => {
    if (!metamaskInstalled || !web3Instance.current) {
      return;
    }
    setLoading(true);

    if (window.ethereum.networkVersion == CHAIN_ID) {
      setCanInitiateTrans(true);
    } else if (window.ethereum.networkVersion != CHAIN_ID) {
      setRejectedChainDownload(false);
      setRejectedChainSwitch(false);
      setShowErrorPopup(false);
      setLoading(true);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3Instance.current.utils.toHex(+CHAIN_ID) }],
        });
        // reaching here means, we have successfully switched chains, call this hook again to set all states correctly
        setReRequestSwitchChain((prev) => !prev);
      } catch (err) {
        setLoading(false);
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          try {
            setLoading(true);
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: CHAIN_NAME,
                  chainId: web3Instance.current.utils.toHex(+CHAIN_ID),
                  nativeCurrency: {
                    name: CURRENCY_NAME,
                    decimals: 18,
                    symbol: CURRENCY_SYMBOL,
                  },
                  rpcUrls: [CHAIN_RPC_URL],
                },
              ],
            });

            // reaching here means, our chain has just been added to metamask, we should now re request the chain switch from user
            setReRequestSwitchChain((prev) => !prev);
          } catch (error) {
            console.log("user rejected adding the chain", error);
            setRejectedChainDownload(true);
            setLoading(false);
            setShowErrorPopup(true);
            setErrorPopupMessage("You must approve adding the chain");
          }
        } else if (err.code == 4001) {
          console.log("user rejected chain switch");
          setRejectedChainSwitch(true);

          setShowErrorPopup(true);
          setErrorPopupMessage("You must approve switching chains");
        }
      }
    }
  };

  // Verify if the selected wallet exists in MetaMask
  const verifyWallet = async () => {
    const accounts = (await web3Instance.current.eth.getAccounts()) || [];

    if (
      !accounts.some(
        (account) =>
          account.toLowerCase() === selectedAccountRef.current.toLowerCase()
      )
    ) {
      // alert(
      //   "Wallet not found in MetaMask. Please add the wallet and try again."
      // );
      setLoading(false);
      setErrorPopupMessage(
        "Wallet not found in MetaMask. Please add the wallet and try again."
      );
      setShowErrorPopup(true);
      setShowErrorTip(true);
      return false;
    }
    return true;
  };

  // Process subscription or one-time payment
  const processPayment = async () => {
    if (!web3Instance.current || !tokenAdded.current) {
      return;
    }
    if (!(await verifyWallet())) return;

    // const transcationTimeout = setTimeout(() => {
    //   setLoading(false);
    //   setErrorPopupMessage("Your transaction timed out. Please try again");
    //   setShowErrorPopup(true);
    //   setShowErrorTip(true);
    // }, 10 * 60 * 1000);

    const speedUpTimeout = setTimeout(() => {
      setProcessingText(
        "Your transaction is being processed. If your transaction is taking longer than expected, you can try speeding it up the by selecting a higher gas fee option in MetaMask"
      );
      setShowProcessingTip(true);
    }, 10 * 1000);

    const web3ToAddress = localStorage.getItem("web3ToAddress");
    const tx = isSubscription
      ? FSTRContract.current.methods.approve(
          web3ToAddress,
          "0x8000000000000000000000000000000000000000000000000000000000000000"
        )
      : FSTRContract.current.methods.transfer(
          web3ToAddress,
          web3Instance.current.utils.toWei(transactionRes?.amount, "ether")
        );

    try {
      const estimateGas = await tx.estimateGas({
        from: selectedAccountRef.current,
      });
      let receipt;

      if (
        process.env.REACT_APP_ENVIRONMENT == "staging" ||
        process.env.REACT_APP_ENVIRONMENT == "main"
      ) {
        // Gas fee calculation
        const baseFeeGwei =
          +process.env.REACT_APP_WEB3_BASE_Fee_GWEI || 73.878795854; // Base fee
        const priorityFeeGwei =
          +process.env.REACT_APP_WEB3_PRIORITY_Fee_GWEI || 32.93757229; // Priority fee
        const gasPriceGwei = baseFeeGwei + priorityFeeGwei; // Total gas price in GWEI
        const gasPriceWei = web3Instance.current.utils.toWei(
          gasPriceGwei.toString(),
          "gwei"
        ); // Convert to WEI

        // Sending the transaction with custom gas price
        receipt = await tx.send({
          from: selectedAccountRef.current,
          gas: Number(estimateGas),
          gasPrice: gasPriceWei, // Use the calculated gas price
        });
      } else
        receipt = await tx.send({
          from: selectedAccountRef.current,
          gas: Number(estimateGas),
        });

      // await tx.send({
      //   from: selectedAccountRef.current,
      //   gas: estimateGas,
      // });

      // reaching here means transaction is completed from blockchain
      console.log("purchase success", receipt);
      // storing the transaction hash for later to verify purchase
      localStorage.setItem("transactionhashfstr", receipt.transactionHash);
      clearTimeout(speedUpTimeout);
      // clearTimeout(transcationTimeout);
      setShowProcessingTip(false);
      setProcessingText("Transaction is done, let us verify it for you!");
    } catch (error) {
      // either user rejected the transaction or user has insufficient funds
      console.log("error in transaction", error);

      setLoading(false);
      setRejectedTransaction(true);
      setErrorPopupMessage(
        "You must approve the transaction request and have sufficient funds"
      );
      setShowErrorPopup(true);
    }
  };

  const connectMetaMask = () => {
    checkMetamask();

    const checkAndReload = async () => {
      const isInstalled = await checkMetamask();
      if (!isInstalled) {
        // Refresh the current page
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", checkAndReload);
    return () => {
      return document.removeEventListener("visibilitychange", checkAndReload);
    };
  };

  const getOrderDetails = async (orderId) => {
    try {
      let res = await fetch(
        `${BASE_URL}${EndpointsSlug.GET_MY_ORDER_DETAIL}?orderId=${orderId}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }
      // console.log("response: ", res);
      if (res?.response?.statusEnum !== 1) {
        setLoading(false);
        setAlertSuccess("error");
        setAlertHeading("Something went wrong!");
        setAlertMessage(
          "An error occurred during the payment process, and your session has ended. Please try again.  Don’t worry, your cart has been saved. "
        );
        setShowAlert(true);
      } else {
        return res;
      }
      setQrLoading(false);
    } catch (error) {
      setErrorPopupMessage("Something went wrong!");
      return;
    }
  };

  useEffect(() => {
    (async () => {
      if (orderId) {
        const storedData = JSON.parse(
          await sessionStorage.getItem(`${orderId}`)
        );
        if (storedData) {
          const data = await getOrderDetails(orderId);
          if (data?.response?.data?.status == "await") {
            const existingData = sessionStorage.getItem(`${orderId}`);
            let gatewayTransaction = {
              amount: data?.response?.data?.total_amount,
              paymentHash: data?.response?.data?.paymentHash,
              web3FromAddress: data?.response?.data?.web3FromAddress,
              web3ToAddress: data?.response?.data?.web3ToAddress,
              _id: data?.response?.data?._id,
            };
            let parsedData = JSON.parse(existingData);
            // Update fields as needed
            parsedData.gatewayTransaction = gatewayTransaction;

            sessionStorage.setItem(`${orderId}`, JSON.stringify(parsedData));

            localStorage.setItem(
              "transactionpaymenthashfstr",
              data?.response?.data?.paymentHash
            );
            selectedAccountRef.current = data?.response?.data?.web3FromAddress;
            localStorage.setItem(
              "web3ToAddress",
              data?.response?.data?.web3ToAddress
            );
            const storedData = JSON.parse(sessionStorage.getItem(`${orderId}`));
            setTransactionRes(storedData.gatewayTransaction);
            setStoreSessionData(storedData);
            const expireTime = storedData.expiryTime;
            const currentTime = Date.now();

            // Calculate the time difference in milliseconds
            const timeDifference = expireTime - currentTime;

            // Convert the difference to seconds
            const totalSeconds = Math.floor(timeDifference / 1000);

            // Calculate minutes and seconds
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            setMinutes(minutes);
            setSeconds(seconds);
          } else if (data?.response?.data?.status == "success") {
            setAlertMessage("you have already purchased this Asset!");
            setShowAlreadyInAsset(true);
            console.log("Already in asset");
          } else if (data?.response?.data?.status == "cancelled") {
            setLoading(false);
            setAlertSuccess("error");
            setAlertHeading("Something went wrong!");
            setAlertMessage(
              "An error occurred during the payment process, and your session has ended. Please try again.  Don’t worry, your cart has been saved. "
            );
            setShowAlert(true);
            return;
          } else if (data?.response?.data?.status == "expired") {
            setLoading(false);
            setAlertSuccess("error");
            setAlertMessage(
              "Your order has expired. If you have already made your payment, the order status will be updated automatically upon successful verification."
            );
            setShowAlert(true);
            return;
          } else if (data?.response?.data?.status == "pending") {
            handlePaymentRequestTimeOut();
          } else {
            verifyTransactionOnBackend();
          }
        } else {
          // handlePaymentRequestTimeOut();
          verifyTransactionOnBackend();
        }
      }
    })();
  }, [orderId]);

  const handleCreatePayment = async () => {
    await connectMetaMask();

    // setButtonBrowserExtension(true);
    if (!metamaskInstalled) {
      console.log("metamask not installed");
      // alert(
      //   "Wallet not found in MetaMask. Please add the wallet and try again."
      // );
      return;
    }

    await initWeb3();

    await switchChain();

    if (!web3Instance.current) {
      return;
    }
    await addTokenToWallet(2);

    await processPayment();
  };

  const mobileButtonTransaction = () => {
    if (transactionRes?.amount) {
      const contractAddress = process.env.REACT_APP_WEB3_FSTR_CONTRACT;
      const web3ToAddress = transactionRes?.web3ToAddress;
      let amount = transactionRes?.amount;
      let totalAmount = amount * Math.pow(10, 18);
      const url = `ethereum:${contractAddress}/transfer?address=${web3ToAddress}&uint256=${totalAmount}`;
      window.location.href = url;
    }
  };

  // will verify the transaction using transaction hash
  const verifyTransactionOnBackend = async () => {
    try {
      let paymentHash = "";
      if (storeSessionData?.gatewayTransaction) {
        paymentHash = storeSessionData?.gatewayTransaction?.paymentHash;
      } else {
        paymentHash = localStorage.getItem("transactionpaymenthashfstr");
      }

      let payload = {
        paymentHash: paymentHash,
      };

      let res = await fetch(
        BASE_URL + EndpointsSlug.VERIFY_CRYPTO_TRANSACTION,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum != 1) {
        setLoading(false);
        setAlertSuccess("error");
        setAlertHeading("Something went wrong!");
        setAlertMessage(
          "An error occurred during the payment process, and your session has ended. Please try again.  Don’t worry, your cart has been saved. "
        );
        setShowAlert(true);
        return;
      }
      const status =
        res.response.data.gatewayTransactionDetails.status ?? "failed";
      if (status === "cancelled" || status === "failed") {
        setAlertSuccess("error");
        setAlertHeading("Something went wrong!");
        setAlertMessage(
          "An error occurred during the payment process, and your session has ended. Please try again.  Don’t worry, your cart has been saved. "
        );
        setShowAlert(true);
      } else if (status == "success") {
        setShowAlreadyInAsset(true);
        console.log("Already in asset");
        setAlertMessage("you have already purchased this Asset!");
        setMinutes(0);
        setSeconds(0);
      } else if (status == "expired") {
        setLoading(false);
        setAlertSuccess("error");
        setAlertMessage(
          "Your order has expired. If you have already made your payment, the order status will be updated automatically upon successful verification."
        );
        setShowAlert(true);
        return;
      } else if (
        status == "pending" ||
        (status == "await" && !storeSessionData?.gatewayTransaction)
      ) {
        handlePaymentRequestTimeOut();
      }
    } catch (error) {
      setAlertSuccess("error");
      setAlertHeading("Something went wrong!");
      setAlertMessage(
        "An error occurred during the payment process, and your session has ended. Please try again.  Don’t worry, your cart has been saved."
      );
      setShowAlert(true);
      return;
    }
  };

  useEffect(() => {
    if (storeSessionData?.gatewayTransaction?.paymentHash) {
      verifyTransactionOnBackend();
    }
  }, [storeSessionData?.gatewayTransaction?.paymentHash]);

  // if (loading) {
  //   return (
  //     <div className="relative w-full min-h-screen flex flex-col justify-center items-center">
  //       <Loader />
  //       {canInitiateTrans && (
  //         <p className="text-center mt-6 text-white/50 font-medium">
  //           Processing Transaction
  //         </p>
  //       )}
  //       <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
  //     </div>
  //   );
  // }

  return (
    <div className="relative min-h-screen w-full max-sm:px-0 px-8 ">
      {(rejectedChainDownload || rejectedChainSwitch) && (
        <CheckoutFstr
          open={showErrorPopup}
          canClose={false}
          heading={`${
            rejectedChainDownload
              ? "To proceed you must add the required FSTR chain to MetaMask"
              : "To proceed you must switch to the required FSTR chain in MetaMask"
          }`}
          btnText={`${
            rejectedChainDownload ? "Add FSTR Chain" : "Switch FSTR Chain"
          }`}
          btnAction={() => {
            setLoading(true);
            setShowErrorPopup(false);
            setReRequestSwitchChain((prev) => !prev);
          }}
        />
      )}
      {rejectedWalletConnect && (
        <CheckoutFstr
          open={showErrorPopup}
          canClose={false}
          heading={"To proceed you must connect your wallet"}
          btnText={"Connect Wallet"}
          btnAction={() => {
            setShowErrorPopup(false);
            setRejectedWalletConnect(false);
            setReRequestConnectWallet((prev) => !prev);
          }}
        />
      )}
      {rejectedTransaction && (
        <CheckoutFstr
          open={showErrorPopup}
          canClose={false}
          heading={"Transaction Failed"}
          btnText={"Try Again"}
          message={
            "To complete the purchase you must approve the transaction request and you must have sufficient funds in your account."
          }
          btnAction={() => {
            setShowErrorPopup(false);
            setRejectedTransaction(false);
            setReRequestTransaction((prev) => !prev);
          }}
        />
      )}
      {showErrorPopup && (
        <CheckoutFstr
          open={showErrorPopup}
          canClose={false}
          heading={"Transaction Failed"}
          btnText={"Try Again"}
          message={errorPopupMessage}
          btnAction={() => {
            setShowErrorPopup(false);
            setRejectedTransaction(false);
            setReRequestTransaction((prev) => !prev);
          }}
        />
      )}

      {showSuccessPopup && (
        <TransactionSuccess open={showSuccessPopup} canClose={false} />
      )}

      {!metamaskInstalled && <MetamaskInstall open={true} canClose={false} />}

      {openCancelPaymentPopup && (
        <TransactionCancel
          open={true}
          canClose={false}
          goBack={goBack}
          onCancel={cancelTransaction}
        />
      )}

      <div className="flex items-center justify-center min-h-screen  px-4">
        <div className="bg-[#242424] relative rounded-lg shadow-lg p-6 sm:p-8 w-full max-w-xl">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => {
              setOpenCancelPaymentPopup(true);
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="28"
                height="28"
                rx="14"
                fill="white"
                fillOpacity="0.1"
              />
              <path
                d="M19.6875 8.3125L8.3125 19.6875M8.3125 8.3125L19.6875 19.6875"
                stroke="white"
                strokeOpacity="0.5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {/* Header and Tabs */}
          <div className="text-[#FFFFFF]">
            <h2 className="text-lg font-bold max-sm:text-center">
              Select Payment Option
            </h2>
            <div className="flex mt-4 border-b border-gray-600">
              <button
                className={`flex-1 py-3 font-semibold text-[18px] leading-8 ${
                  activeTab === "autoPay"
                    ? "text-[#FFFFFF] border-b-2 border-[#FBBC5E]"
                    : "text-[#FFFFFF99]"
                } max-sm:text-[12px]`}
                onClick={() => setActiveTab("autoPay")}
              >
                Auto-Pay Scan
              </button>
              <button
                className={`flex-1 py-3 font-semibold text-[18px] leading-8 ${
                  activeTab === "manualEntry"
                    ? "text-[#FFFFFF] border-b-2 border-[#FBBC5E]"
                    : "text-[#FFFFFF99]"
                } max-sm:text-[12px]`}
                onClick={() => setActiveTab("manualEntry")}
              >
                Manual Entry Scan
              </button>
            </div>
          </div>

          {/* QR Code Section */}
          <div className="flex justify-center mt-4">
            {qrLoading ? (
              <div className="w-32 h-32 flex justify-center items-center sm:w-2/4 sm:h-56 p-2 rounded-lg">
                <CircularProgress
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#535353",
                  }}
                />{" "}
              </div>
            ) : (
              <>
                {activeTab == "autoPay" ? (
                  <img
                    src={autoPayScanQr}
                    alt="Auto Pay QR Code"
                    className="w-40 h-40 sm:w-2/4 sm:h-56 p-2 rounded-lg bg-transparent"
                  />
                ) : (
                  <img
                    src={manualEntryScanQr}
                    alt="Manual Entry QR Code"
                    className="w-40 h-40 sm:w-2/4 sm:h-56 p-2 rounded-lg bg-transparent"
                  />
                )}
              </>
            )}
          </div>
          <p className="text-[#FFFFFF] font-semibold text-center text-sm mt-4">
            You can scan the QR code using the scanner in your Web3 wallet app
            on your mobile device to complete the payment.
          </p>

          {/* Payment Details */}
          <div className="mt-6">
            <div className="border border-[#5F5F5F] text-white gap-4 p-4 rounded-lg text-sm">
              <p className="text-[#FFFFFF99] font-semibold text-[16px] py-2">
                Payment details
              </p>

              <div className="flex max-sm:block justify-between items-center ">
                <p className=" break-[break-word] break-words py-2">
                  <span className="text-[#FFFFFF99] font-semibold text-[16px]">
                    From:
                  </span>{" "}
                  <span className="font-normal">
                    {transactionRes?.web3FromAddress}
                  </span>
                </p>
                <span
                  onClick={() => setShowChangeWalletPopup(true)}
                  className="text-[#FBBC5E] font-semibold text-[12px] cursor-pointer"
                >
                  Change
                </span>
              </div>
              <div className="flex max-sm:block justify-between items-center ">
                <p className="break-[break-word] break-words  py-2">
                  <span className="text-[#FFFFFF99] font-semibold text-[16px]">
                    To:
                  </span>{" "}
                  <span className="font-normal">
                    {" "}
                    {transactionRes?.web3ToAddress}
                  </span>
                </p>{" "}
                <button
                  onClick={() => copyToClipboard(transactionRes?.web3ToAddress)}
                  className="text-[#FFFFFF] border border-[#939393] py-1 font-semibold px-3 rounded-[25px] hover:bg-white/[0.90] hover:text-dark"
                >
                  Copy
                </button>
              </div>
              <p className="py-2">
                <span className="text-[#FFFFFF99] font-semibold text-[16px]">
                  {" "}
                  Amount:
                </span>
                <span className="font-semibold text-[16px] text-[#FBBC5E]">
                  {" "}
                  {transactionRes?.amount} FSTR
                </span>
              </p>
            </div>
          </div>

          <div>
            <p className="text-[#FFFFFF] text-[18px] font-semibold mt-4 text-center">
              OR
            </p>
          </div>

          {showChangeWalletPopup && (
            <WalletAddressModel
              addressList={walletAddressList}
              onCancel={onWalletPopupClose}
              onNext={onActionNextBtnClick}
              btnText="Change"
              selectedWalletId={transactionRes?.web3FromAddress}
            />
          )}

          {/* Time 5 minute */}
          {transactionRes?.paymentHash && (
            <div className="flex">
              <span className="text-[#777777] font-medium text-sm mt-4 text-center">
                Your transaction will remain valid for 7 minutes. Please
                complete the payment within this timeframe:{" "}
                <span className="text-[#FBBC5E] font-medium text-sm inline-block w-[40px]">
                  {" "}
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </span>
              </span>
            </div>
          )}

          {/* Action Button */}
          <div className="text-center mt-6">
            {isMobile ? (
              <button
                onClick={mobileButtonTransaction}
                disabled={loading || transactionSuccess}
                className="px-4 w-full flex justify-center  py-3 text-center font-bold rounded-lg text-black  bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] hover:from-[#DB9149] hover:to-[#DB9149]"
              >
                Pay using Web3 wallet app
              </button>
            ) : loading ? (
              <Loader />
            ) : (
              <button
                onClick={handleCreatePayment}
                disabled={loading || transactionSuccess}
                className="px-4 w-full flex justify-center  py-3 text-center font-bold rounded-lg text-black  bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] hover:from-[#DB9149] hover:to-[#DB9149]"
              >
                {` Pay using Browser Extension`}
              </button>
            )}
          </div>

          {/* Notes for auto pay */}
          {activeTab === "autoPay" && (
            <p className="text-[#777777] font-medium text-sm mt-4 text-center">
              Note: Some wallets may not support the auto-pay method. Kindly
              switch to the manual pay method to complete your transaction.
            </p>
          )}

          {/* Notes for manual entry */}
          {activeTab === "manualEntry" && (
            <p className="text-[#6BFEF6] font-medium text-sm mt-4 text-center">
              Enter the payment amount carefully as displayed above during the
              transaction. Double-check the amount before confirming, as
              incorrect entries will result in a failed transaction.
            </p>
          )}
        </div>
      </div>

      {showAlert && (
        <Alert
          open={showAlert}
          message={alertMessage}
          isSuccess={alertSuccess}
          heading={alertHeading}
          canClose={false}
          onClose={goBack}
          buttonText={"Retry Payment"}
        />
      )}

      {showCommonErrorPopup && (
        <Alert
          open={showCommonErrorPopup}
          message={alertMessage}
          isSuccess={alertSuccess}
          onClose={() => {
            setShowCommonErrorPopup(false);
          }}
        />
      )}

      {showAlreadyInAsset && (
        <Alert
          open={showAlreadyInAsset}
          message={alertMessage}
          isSuccess={alertSuccess}
          canClose={false}
          onClose={() => {
            setShowAlreadyInAsset(false);
            navigate("/marketplace");
          }}
        />
      )}
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </div>
  );
};

export default CheckoutFSTR;
