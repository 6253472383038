import React, { useState, useEffect } from "react";
import ShadowFrame from "components/shadow-frame";
import MyOrderItemModal from "components/Modal/MyOrderItem/MyOrderItemModal";
import OrderCart from "../../../assets/images/order-cart.png";
import { useGetMyOrdersListQuery } from "store/slicer/MyOrders";
import Loader from "components/Loader/Loader";
import { useNavigate } from "react-router-dom";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module My-Order-Home-Component
 *@developer Amardeep Singh
 */

// Default Transaction Data
// const UserTransaction = [
//   {
//     orderId: "TRX987654321",
//     totalAmount: "24.00 STARS",
//     time: "12/10/2023, 12:39 PM",
//     paymentMethod: "FSTR",
//     status: true,
//     action: null,
//   },
//   {
//     orderId: "TRX987654321",
//     totalAmount: "24.00 USD",
//     time: "12/10/2023, 12:39 PM",
//     paymentMethod: "USD",
//     status: false,
//     action: null,
//   },
//   {
//     orderId: "TRX987654321",
//     totalAmount: "24.00 USD",
//     time: "12/10/2023, 12:39 PM",
//     paymentMethod: "USD",
//     status: false,
//     action: null,
//   },
// ];

function formatDate(date) {
  // Extract individual components from the Date object
  var month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
  var day = date.getDate();
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  // Format the date and time string
  var formattedDate =
    day +
    "/" +
    month +
    "/" +
    year +
    ", " +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    " " +
    period;

  return formattedDate;
}

// my-order component in assets section
const MyOrderHome = () => {
  const [isInviteModalStatus, setInviteModal] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { data, isLoading, isFetching, refetch } = useGetMyOrdersListQuery({
    page: page,
    size: 10,
  });

  const [buttonStates, setButtonStates] = useState({});

  const handleInviteModal = () => {
    setInviteModal(!isInviteModalStatus);
  };

  const goToCheckoutPage = (orderId) => {
    console.log("Checkout page is called");
    navigate(`/checkoutfstr`, {
      state: { orderId: orderId },
    });
  };

  useEffect(() => {
    const fetchStoredData = async () => {
      const states = {};
      for (const order of orderData) {
        const storedData = JSON.parse(
          await sessionStorage.getItem(`${order._id}`)
        );
        if (storedData) {
          const expireTime = storedData?.expiryTime;
          const currentTime = Date.now();
          if (currentTime > expireTime) {
            // Order expired, remove from storage
            sessionStorage.removeItem(`${order._id}`);
          } else {
            states[order._id] = storedData;
          }
        }
      }
      setButtonStates(states);
    };

    if (orderData?.length) fetchStoredData();
  }, [orderData]);

  const renderStatus = (orderId, status) => {
    const storedData = buttonStates[orderId]; // Check session data for this order
    if (storedData && status === "await") {
      return <span className="text-[#6bb0fa] font-medium">Awaiting</span>;
    } else if (status === "expired") {
      return <span className="text-[#FF6458] font-medium">Expired</span>;
    } else if (status === "cancelled") {
      return <span className="text-[#FF6458] font-medium">Cancelled</span>;
    } else {
      return <span className="text-[#FBBC5E] font-medium">Pending</span>;
    }
  };

  const renderOrderButton = (orderId, status) => {
    const storedData = buttonStates[orderId];
    if (storedData && status === "await") {
      return (
        <button
          className="border border-[#a5a3a3] text-[#6bb0fa] w-[98px] br-[8px] text-[12px] font-bold font-body rounded-[20px]"
          onClick={() => goToCheckoutPage(orderId)}
        >
          Pay Now
        </button>
      );
    } else {
      return (
        <button
          className="border border-[#a5a3a3] w-[98px] br-[8px] text-[12px] font-bold font-body rounded-[20px]"
          onClick={() => {
            setInviteModal(!isInviteModalStatus);
            setOrderId(orderId);
          }}
        >
          View Items
        </button>
      );
    }
  };

  useEffect(() => {
    if (page === 1) {
      if (data?.response?.data?.gateways?.length) {
        setOrderData(data.response.data.gateways);
      }
    } else {
      if (data?.response?.data?.gateways?.length) {
        setOrderData((prevData) => [
          ...prevData,
          ...data.response.data.gateways,
        ]);
      }
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <div className="__my_transaction__container__main mb-[100px] container mx-auto px-[15px] py-[50px] max-[768px]:px-[15px] max-[768px]:py-[40px] max-[1280px]:px-[15px] relative">
      <ShadowFrame className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <ShadowFrame className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <div className="__transaction__header__main relative">
        <h4 className="font-heading text-[24px] max-[768px]:text-[18px]">
          My Orders
        </h4>
      </div>
      <div className="__transaction__content__main h-[90%] relative">
        {!isLoading && orderData && orderData.length > 0 && (
          <div className="__transaction__table__content_main w-[100%] mt-[30px] overflow-x-scroll">
            <table className="table-auto w-full">
              <thead>
                <tr className="[&>th]:text-[#FFFFFF99] [&>th]:font-normal [&>th]:text-[12px] [&>th]:text-left [&>th]:px-[10px] [&>th]:py-[10px] [&>th]:border-b [&>th]:border-b-[#8d8a8a80]">
                  <th>Order ID</th>
                  <th>Order Placed on</th>
                  <th>Total Amount</th>
                  <th>Payment method</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orderData.map((_tr, index) => {
                  return (
                    <tr
                      key={index}
                      className="[&>td]:px-[10px] [&>td]:py-[18px] [&>td]:text-[14px] [&>td]:border-b [&>td]:border-b-[#5A5A5A80]"
                    >
                      <td>{_tr?.orderID}</td>
                      <td>{formatDate(new Date(_tr?.createdAt))}</td>
                      <td>
                        {_tr?.total_amount} {_tr?.currency}
                      </td>
                      <td>{_tr?.currency}</td>
                      <td>
                        {
                          _tr?.status === "success" ? (
                            <span className="text-[#56AA6D] font-medium">
                              Success
                            </span>
                          ) : (
                            renderStatus(_tr?._id, _tr?.status)
                          )
                          // _tr?.status === "pending" ? (
                          //   <span className="text-[#FF6458] font-medium">
                          //     Pending
                          //   </span>
                          // ) : (
                          //   <span className="text-[#FF6458] font-medium">
                          //     Failed
                          //   </span>
                          // )
                        }
                      </td>
                      <td>
                        <td>{renderOrderButton(_tr?._id, _tr?.status)}</td>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {!isLoading && orderData.length === 0 && (
          <div className="__transaction__empty__container flex flex-col mt-[200px] items-center h-[100%] max-[768px]:justify-start max-[768px]:pt-[30px] max-[1280px]:pt-[30px] max-[1280px]:justify-start gap-3">
            <img src={OrderCart} className="w-[60px] h-[60px]" />
            <span className="text-[#FFFFFF99] text-[16px] font-medium font-body max-[768px]:text-[14px]">
              No orders placed yet!
            </span>
          </div>
        )}
        {isFetching && (
          <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
            <div className="__no_friend_container flex flex-col">
              <h4 className="text-[16px] text-[#7A7A7A] font-body font-medium">
                <Loader />
              </h4>
            </div>
          </div>
        )}
        {data?.response?.data?.gateways?.length === 10 && (
          <div className="flex justify-center mt-[30px]">
            <button
              onClick={() => setPage(page + 1)}
              className="inline-flex border cursor-pointer text-sm md:text-base leading-none rounded-2xl border-sec btn-outline hover:text-black hover:font-semibold px-6 py-3 md:py-4 md:px-8 mt-6 font-medium"
            >
              Load More
            </button>
          </div>
        )}
      </div>

      {isInviteModalStatus ? (
        <MyOrderItemModal
          handleModalStatus={handleInviteModal}
          orderId={orderId}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyOrderHome;
